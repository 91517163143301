import React from 'react';

import type { IFeedItem } from 'api/feed/types';
import { FeedItem } from 'common/components/FeedItem';

import type { IVirtuosoContext } from './types';

export function computeItemKey(index: number, item: IFeedItem) {
  return item.feedItemId as string;
}

export function getItemContent(
  index: number,
  item: IFeedItem,
  context: IVirtuosoContext,
) {
  return (
    <FeedItem
      truncate
      item={item}
      onFocus={handleFocus}
      promote={context.promote}
      disabledComments={context.disabledComments}
      aria-posinset={index + 1}
      aria-setsize={context.size}
      focused={index === context.scrolledTo}
    />
  );

  function handleFocus() {
    context.setActive(index);
  }
}
