import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import cls from 'classnames';
import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { IFeedState } from 'store/feed/types';
import { IFeedItem } from 'api/feed/types';

import { clamp } from 'common/utils/utils';
import { FeedItem, FeedItemSkeleton } from 'common/components/FeedItem';
import { ErrorState } from 'wui/ErrorState';

import { components } from './virtual';
import { computeItemKey, getItemContent } from './helpers';
import { useKeyboardScroll } from './useKeyboardScroll';
import { useFullscreenFix } from './useFullscreenFix';
import { EmptyResults } from './EmptyResults';

import classes from './FeedItemList.scss';

interface IFeedProps {
  state: IFeedState;
  items: IFeedItem[];

  showGroupName?: boolean;
  disabledComments?: boolean;
  className?: string;

  slots?: {
    empty?: React.ReactElement;
  };

  onCreatePost?(): void;
  onFetch(cursor?: string | number): void;
}

export function FeedItemList(props: IFeedProps) {
  useFullscreenFix();

  const { state, items, onCreatePost, className, slots, disabledComments } =
    props;

  const { t } = useTranslation();
  const { isSSR, isEditor, isMobile } = useEnvironment();

  const keyboardScroller = useKeyboardScroll({
    size: items.length,
  });

  if (state.statuses.fetch.pending) {
    return (
      <div className={cls(classes.root, className)}>
        <div className={classes.list}>
          <FeedItemSkeleton />
          <FeedItemSkeleton />
          <FeedItemSkeleton />
        </div>
      </div>
    );
  }

  if (state.statuses.fetch.error) {
    return (
      <ErrorState
        wired
        title={t('groups-web.error-state.title')}
        actionLabel={t('groups-web.error-state.retry.label')}
        onRetry={handleRetry}
      />
    );
  }

  if (!items.length) {
    return slots?.empty || <EmptyResults onCreatePost={onCreatePost} />;
  }

  if (isEditor) {
    return (
      <div className={cls(classes.root, className)}>
        <div className={classes.list}>
          {items.map((item) => (
            <ErrorMonitorBoundary
              key={item.feedItemId}
              fallback={
                <ErrorState
                  wired
                  title={t('groups-web.error-state.title')}
                  subtitle={t('groups-web.error-state.subtitle')}
                  actionLabel={t('groups-web.error-state.retry.label')}
                  onRetry={handleRetry}
                />
              }
            >
              <FeedItem
                truncate
                item={item}
                promote={props.showGroupName}
                disabledComments={disabledComments}
              />
            </ErrorMonitorBoundary>
          ))}
        </div>
      </div>
    );
  }

  const defaultItemHeight = isMobile ? 250 : 400;
  const viewportSize = clamp(isSSR ? 0 : window.innerHeight, 0, 1500);
  const scale = isMobile ? 2 : 1; // Desktop comes with comments opened - can lead to FPS drops

  return (
    <ErrorMonitorBoundary
      fallback={
        <ErrorState
          wired
          title={t('groups-web.error-state.title')}
          subtitle={t('groups-web.error-state.subtitle')}
          actionLabel={t('groups-web.error-state.retry.label')}
          onRetry={handleRetry}
        />
      }
    >
      <Virtuoso
        role="feed"
        aria-labelledby="feed-title"
        aria-busy={state.statuses.fetchMore.pending}
        ref={keyboardScroller.$virtuoso}
        data-hook="feed"
        useWindowScroll
        data={items}
        endReached={fetchMore}
        components={components}
        itemContent={getItemContent}
        computeItemKey={computeItemKey}
        className={cls(classes.root, className)}
        defaultItemHeight={defaultItemHeight}
        initialItemCount={state.nextCursor ? items.length - 1 : items.length}
        increaseViewportBy={{
          top: viewportSize * scale,
          bottom: viewportSize * scale,
        }}
        context={{
          size: items.length,
          onRetry: handleRetry,
          promote: props.showGroupName,
          disabledComments: props.disabledComments,
          scrolledTo: keyboardScroller.scrolledTo,
          setActive: keyboardScroller.setActive,
        }}
      />
    </ErrorMonitorBoundary>
  );

  function handleRetry() {
    props.onFetch();
  }

  function fetchMore() {
    const { nextCursor, statuses } = state;

    if (
      nextCursor &&
      !statuses.fetchMore.pending &&
      !statuses.fetchMore.error
    ) {
      props.onFetch(nextCursor);
    }
  }
}

FeedItemList.displayName = 'FeedItemList';
